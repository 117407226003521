import * as React from "react"
import { useEffect, useState } from "react";
import * as styles from './styles.module.scss'
import AsevenLogo from '../../images/logo/aseven-logo-white.png';

const FooterComponent = () => {

  const [isMobile, setIsMobile] = useState(true);

  useEffect(() => {
    setIsMobile(window.innerWidth < 768);
  }, [])

  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <img 
          src={AsevenLogo} 
          className={styles.logo}
        />

        <div className={styles.menus}>
          <a href={'#our-services'}>Our Services</a>
          <a href={'#our-works'}>Our Works</a>
          <a href={'#what-we-do'}>What We Do</a>
          <a href={'#contact-us'}>Contacts</a>
          <a href={'#our-office'}>About Us</a>
        </div>

        <div className={styles.copyright}>
          <p>© Copyright 2022</p>
          <p>PT BADAN EMAS INDONESIA</p>
        </div>
      </div>
     </footer>
  )
}

export default FooterComponent