import * as React from "react"
import { useEffect, useRef, useState } from "react"
import VisibilityComponent from "../Common/Components/Visibility/VisibilityComponent"
import * as styles from './styles.module.scss'
import AsevenLogo from '../../images/logo/aseven-logo.png';
import BurgerIcon from "../Common/Components/BurgerIcon/BurgerIcon";
import { navigate } from "gatsby";

const HeaderComponent = () => {
  const [position, setPosition] = useState(0);
  const positionRef = useRef<number>(0);
  const [isMobile, setIsMobile] = useState(true);

  const [isShowingSidebar, setIsShowingSidebar] = useState(false);

  const [isShowingNav1, setIsShowingNav1] = useState(true);
  const [isShowingNav2, setIsShowingNav2] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', onScroll, { passive: true });

    setIsMobile(window.innerWidth < 768);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [])

  useEffect(() => {
    positionRef.current = position;
  })
  const prevPosition = positionRef.current;

  const onScroll = () => {
    const newPosition = window.pageYOffset;
    setPosition(newPosition);
  };

  useEffect(() => {
    setIsShowingNav1(position < 100);
    setIsShowingNav2(position >= 600);
  }, [position]);

  return (
    <header>
      {/* Mobile */}
      <VisibilityComponent isVisible={isMobile}>
        <nav className={styles.navMobile}>
          <div>
            <BurgerIcon
              isOpen={isShowingSidebar}
              onClick={() => setIsShowingSidebar(!isShowingSidebar)}
            />

            <img src={AsevenLogo} className={styles.logo} onClick={() => navigate('/')} />

          </div>

          <VisibilityComponent isVisible={isShowingSidebar}>
            <div className={styles.menus}>
              <a href={'#our-services'} onClick={() => setIsShowingSidebar(false)}>Our Services</a>
              <a href={'#our-works'} onClick={() => setIsShowingSidebar(false)}>Our Works</a>
              <a href={'#what-we-do'} onClick={() => setIsShowingSidebar(false)}>What We Do</a>
              <a href={'#contact-us'} onClick={() => setIsShowingSidebar(false)}>Contacts</a>
              <a href={'#our-office'} onClick={() => setIsShowingSidebar(false)}>About Us</a>
            </div>
          </VisibilityComponent>
        </nav>
      </VisibilityComponent>

      {/* Desktop */}
      <VisibilityComponent isVisible={!isMobile && (isShowingNav1 || isShowingNav2)}>
        <nav
          className={`
            ${styles.navDesktop}
            ${isShowingNav1 ? styles.nav1 : styles.nav2}`
          }
        >
          <img src={AsevenLogo} className={styles.logo} onClick={() => navigate('/')} />

          <a href={'#our-services'}>Our Services</a>
          <a href={'#our-works'}>Our Works</a>
          <a href={'#what-we-do'}>What We Do</a>
          <a href={'#contact-us'}>Contacts</a>
          <a href={'#our-office'}>About Us</a>
        </nav>
      </VisibilityComponent>

      {/*{*/}
      {/*  languages.all.map(lang => {*/}
      {/*    return (*/}
      {/*      <Link to={LanguageService.change(location.pathname, lang)}>*/}
      {/*        {lang}*/}
      {/*      </Link>*/}
      {/*    )*/}
      {/*  })*/}
      {/*}*/}
    </header>
  )
}

export default HeaderComponent
