import * as React from "react"
import * as styles from './styles.module.scss'

interface Props {
  isOpen: boolean;
  onClick: () => void;
}

const BurgerIcon = (props: Props) => {
  const { isOpen, onClick } = props;

  return (
    <div className={styles.container} onClick={onClick}>
      <div className={`${styles.icon} ${isOpen ? styles.open : ''}`}>
        <span />
        <span />
        <span />
        <span />
      </div>
    </div>
  );
}

export default BurgerIcon;