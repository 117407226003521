import * as React from "react"
import { ReactNode } from "react"
import HeaderComponent from "../../../Header/HeaderComponent"
import "./general_styles.css"
import { IntlProvider } from "react-intl"
import FooterComponent from "../../../Footer/FooterComponent"

interface Props {
  children: ReactNode;
  messages: Record<string, string>;
}

const Layout = (props: Props) => {
  const { children, messages } = props

  return (
    <IntlProvider
      locale={messages.locale}
      messages={messages}
    >
      <div>
        <HeaderComponent />

        <main>{children}</main>

        <FooterComponent />
      </div>
    </IntlProvider>
  )
}

export default Layout