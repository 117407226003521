import * as React from "react"
import { ReactNode } from "react"
import * as styles from './styles.module.scss'

interface Props {
  isVisible: boolean
  children: ReactNode;
}

const VisibilityComponent = (props: Props) => {
  const { isVisible, children } = props;

  return (
    <div className={isVisible ? '' : styles.hidden}>
      {children}
    </div>
  );
}

export default VisibilityComponent